<template>
  <div class="storelist">
    <div class="item" v-for="(item, index) in listdata" :key="index">
      
        <div class="topitem" @click="toRouter(item.id, item.is_area)">
          <div class="itemleft">
            <div class="title">{{ item.name }}</div>
            <div class="synopsis" v-if="item.address">{{ item.address }}</div>
            <div class="equipment" v-if="item.device_cnt !== undefined">
              设备数量：{{ item.device_cnt }}
            </div>
            <div class="equipment" v-if="item.distance !== undefined">距离{{ item.distance }}</div>
          </div>
          <div class="itemright">
            <div class="collect" :class="item.is_collect == 1 ? 'collected' : ''"
              @click.stop="collectBtn(item.id, index)">
              <div class="icon">
                <van-icon name="star" />
              </div>
              <div class="text">收藏</div>
            </div>
          </div>
        </div>

        <div class="telecall">
          <div class="callitem" v-if="item.sign_name" @click="teleClick(item.sign_phone)">
            <span>{{ item.sign_name }}</span><van-icon name="phone" />
          </div>
          <div class="callitem" v-if="item.maintain_name" @click="teleClick(item.maintain_phone)">
            <span>{{ item.maintain_name !== "运维BD：" ? item.maintain_name : "运维BD：无运维" }}</span><van-icon name="phone" />
          </div>
        </div>

      </div>

  </div>
</template>

<script setup>
import { defineProps, toRefs, watch, defineEmits } from "vue";
import { useRouter } from "vue-router";
import { Toast } from "vant";
import API from "@/axios";
const router = useRouter();

const props = defineProps({
  listdata: {
    type: Array,
    required: false,
  },
  listtype: {
    type: Number,
    required: false,
  },
  detailUrl: {
    type: String,
    requured: false,
  },
  detailUrlList: {
    type: Array,
    requured: false,
  }
});

const { listdata, listtype, detailUrl, detailUrlList } = toRefs(props);

const emit = defineEmits(["setListType", "setData"]);

//门店收藏|取消收藏
function collectBtn(id, index) {
  listdata.value[index].is_collect = !listdata.value[index].is_collect;
  API({
    url: "api/collectStore",
    method: "post",
    data: {
      store_id: id,
    },
  }).then((res) => {
    if (res.data.status == 200) {
      Toast.success(res.data.msg);
      emit("resetList");
    }
    Toast.fail(res.data.msg);
  });
}

function teleClick(val) {
  if (val !== "") {
    window.location.href = "tel://" + val;
  } else {
    Toast.fail("该角色暂无填写手机号");
  }
}

function toRouter(id, is_area) {
  if (detailUrl.value) {
    router.push({
      path: detailUrl.value,
      query: {
        id: id,
      },
    });
  } else if (detailUrlList.value.length > 0) {
    // 存在门店和公海门店两种详情
    // is_area为0时，门店。大于0为公海门店
    is_area = is_area == 0 ? 0 : 1;
    let detailPath = (detailUrlList.value[is_area] ?? '');
    if (detailPath) {
      router.push({
        path: detailPath,
        query: {
          id: id,
        },
      });
    }
  }
}

watch(listdata, (newData, oldData) => {
  if (newData !== oldData) {
    listdata.value = newData;
  }
},
  { deep: true });

watch(listtype, (newData, oldData) => {
  if (newData !== oldData) {
    listtype.value = newData;
  }
},
  { deep: true });
</script>

<style lang="less" scoped>
.storelist {
  margin: 20px;
  padding: 20px;
  background: @font-white-color;
  box-shadow: 0 0 10px #d7d7d7;
  border-radius: 10px;
  box-sizing: border-box;
}

.item {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #dfdfdf;
  .topitem{
    display: flex;
  }
  .itemleft {
    flex: 1;
    min-width: 0;
    margin-right: 10px;
  }

  .title {
    color: @font-color;
    font-size: @subtitle-size;
    line-height: @subtitle-size;
    margin-bottom: 20px;
    font-weight: bolder;
    font-family: "Regular";
  }

  .synopsis {
    color: @font-color;
    font-size: @subtitle-size;
    line-height: @subtitle-size;
    height: @subtitle-size;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 20px;
  }

  .telecall {
    display: flex;
    flex-flow: wrap;

    .callitem {
      background: none;
      border-radius: 10px;
      border: 2px solid @theme-secondary-color;
      color: @theme-secondary-color;
      padding: 5px;
      display: inline-block;
      margin-bottom: 10px;
      margin-right: 10px;

      span {
        margin-right: 10px;
      }
    }
  }

  .equipment {
    display: flex;
    font-size: 28px;
    margin-bottom: 10px;
    color: @font-secondary-color;
  }

  .itemright {
    .collect {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      border: 1px solid #999;
      border-radius: 10px;
      width: 80px;
      height: 80px;

      .icon {
        text-align: center;
        color: #999;
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 10px;
      }

      .text {
        color: #999;
        font-size: 24px;
        line-height: 24px;
      }
    }

    .collected {
      border: 1px solid #f60;

      .icon {
        color: #f60;
      }

      .text {
        color: #f60;
      }
    }
  }
}

.item:last-of-type {
  border: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}</style>
