<template>
  <div class="FileBox">
    <div class="search" v-if="selectindex !== 2">
      <div class="searchSelect" @click="showpup">
        <div class="text">{{ searchType }}</div>
        <img src="@/assets/img/arrow_down.png" alt="" />
      </div>
      <van-search v-model="searchtext" class="searchinput" :placeholder="placeholder" @search="searchClick" />
      <button type="button" @click="searchClick">搜索</button>
    </div>
    <div class="tabs">
      <div class="tabselect">
        <div class="tabitem" :class="index == selectindex ? 'on' : ''" v-for="(item, index) in fileData.selectlist"
          :key="index" @click="selectClick(item.val, index)">
          {{ item.title }}
          <span></span>
        </div>
      </div>
      <div class="btns">
        <button type="button" v-if="fileData.filterlist" @click="switchSelect()">
          <van-icon name="filter-o" color="#666" size="20" />
          <span>筛选</span>
        </button>
      </div>
    </div>

    <div class="selectbox" :class="selectshow ? 'openbox' : ''">
      <div class="mask" @click="switchSelect()"></div>
      <div class="selectmain">
        <div class="seletcont">
          <div class="seleitem" v-for="(item, index) in fileData.filterlist" :key="index">
            <div class="tit">{{ item.title }}</div>
            <div class="selectBtns">
              <button type="button" :class="it.checked ? 'on' : ''" v-for="(it, ix) in item.data" :key="ix"
                @click="clickButton(index, ix, it)">
                {{ it.title }}
              </button>
            </div>
            <div class="daterange" :class="isDate ? 'active' : ''" v-if="item.name == 'date_range'"
              @click="showDate(index)">
              {{ daterange }}
            </div>
          </div>
        </div>
        <div class="bottombtn">
          <button type="button" class="reset" @click="reSetClick">重置</button>
          <button type="button" class="confirm" @click="canFirmClick">确认</button>
        </div>
      </div>
    </div>

    <van-calendar v-model:show="dateshow" type="range" ref="datecalendar" :min-date="new Date(2010, 1, 1)"
      :show-confirm="false" :allow-same-day="true" @confirm="onConfirm" />

    <van-popup v-model:show="pupShow" round position="bottom">
      <van-picker :columns="pupArry" v-model="searchType" @confirm="okClick" @cancel="cancelClick" />
    </van-popup>
  </div>
</template>

<script setup>
import {
  defineProps,
  toRefs,
  ref,
  onMounted,
  defineEmits,
  watch,
  defineExpose,
} from "vue";
const props = defineProps({
  fileData: {
    type: Object,
    required: true,
  },
});
const { fileData } = toRefs(props);

const selectindex = ref(0);

const itemindex = ref(0);

const filterData = ref([]);
// 日期区间
const daterange = ref("请选择日期区间");
const dateshow = ref(false);
const isDate = ref(false);
const datecalendar = ref();
// 筛选框
const selectshow = ref(false);
const date_range = ref("");
const have_device = ref(0);
const store_type = ref(0);
// const is_self = ref(0);
const sys_cost_type = ref(0)
// 搜索
const placeholder = ref("");
const searchType = ref("");
const pupShow = ref(false);
const pupArry = ref([]);
const searchindex = ref(1);
const searchtext = ref("");

const emit = defineEmits(["setListType", "setData"]);

const formatDate = (date) =>
  `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
const onConfirm = (values) => {
  const [start, end] = values;
  dateshow.value = false;
  daterange.value = "日期区间：" + `${formatDate(start)} ~ ${formatDate(end)}`;
  date_range.value = `${formatDate(start)} ~ ${formatDate(end)}`;
  isDate.value = true;

  for ( let item of fileData.value.filterlist) {
    if(item.name=="date_type"){
      for ( let it of item.data){
        it.checked = 0
      }
    }
  }
};

function selectClick(val, index) {
  selectindex.value = index;
  searchtext.value = "";
  let itemVal = val;
  let data = {
    title: "",
    title_type: 1,
    date_range: "",
    have_device: 0,
    store_type: 0,
    // is_self: 0,
    sys_cost_type: 0,
  };

  emit("setListType", itemVal);
  emit("setData", data);
}

function clickButton(index, ix, it) {
  fileData.value.filterlist[index].data.map((item) => {
    item.checked = 0;
  });

  if (fileData.value.filterlist[index].name == "have_device") {
    have_device.value = it.val;
  } else if (fileData.value.filterlist[index].name == "store_type") {
    store_type.value = it.val;
  } else if (fileData.value.filterlist[index].name == "date_type") {
    reSetDate();
  } else if (fileData.value.filterlist[index].name == "sys_cost_type") {
    sys_cost_type.value = it.val;
  }
  // else if (fileData.value.filterlist[index].name == "is_self") {
  //   is_self.value = it.val;
  // }

  fileData.value.filterlist[index].data[ix].checked = 1;
  filterData.value[index] = it.title;

}

function getFilter() {
  for (const item of fileData.value.filterlist) {
    filterData.value.push(item.data[0].title);
  }
}

function showDate(index) {
  itemindex.value = index;
  dateshow.value = true;
}

function switchSelect() {
  selectshow.value = !selectshow.value;
  searchtext.value = "";
  // setTimeout(() => {
  //   reSetClick();
  // }, 500);
}

function reSetClick() {
  // 重置
  filterData.value = [];
  date_range.value = "";
  have_device.value = 0;
  store_type.value = 0;
  // is_self.value = 0;
  sys_cost_type.value = 0;
  fileData.value.filterlist.map((item) => {
    for (let i = 0; i < item.data.length; i++) {
      if (i == 0) {
        item.data[i].checked = 1;
      } else {
        item.data[i].checked = 0;
      }
      if (item.data[i].checked == 1) {
        filterData.value.push(item.data[i].text);
      }
    }
  });

  reSetDate();
}

function reSetDate() {
  datecalendar.value.reset();
  daterange.value = "请选择日期区间";
  isDate.value = false;
}

function canFirmClick() {
  // 确认
  let data = {
    title: "",
    title_type: 1,
    date_range: date_range.value,
    have_device: have_device.value,
    store_type: store_type.value,
    // is_self: is_self.value,
    sys_cost_type: sys_cost_type.value,
  };
  emit("setData", data);
  selectshow.value = !selectshow.value;
}

function searchClick() {
  let data = {
    title: searchtext.value,
    title_type: searchindex.value,
    date_range: date_range.value,
    have_device: have_device.value,
    store_type: store_type.value,
    // is_self: is_self.value,
    sys_cost_type: sys_cost_type.value,
  };
  emit("setData", data);
}

function showpup() {
  pupShow.value = true;
}

function okClick(value, index) {
  searchType.value = value;
  searchindex.value = index + 1;
  pupShow.value = false;
}

function cancelClick() {
  pupShow.value = false;
}

function inisearch(val) {
  placeholder.value = "";
  searchType.value = "";
  pupArry.value = [];
  if (val) {
    placeholder.value = val.title_place;
    searchType.value = val.title_type_place;
    for (const item of val.title_type_val) {
      pupArry.value.push(item.title);
    }
  }
}

watch(
  fileData,
  (newData, oldData) => {
    if (newData !== oldData) {
      fileData.value = newData;
    }
  },
  { deep: true }
);

defineExpose({ inisearch });

onMounted(() => {
  getFilter();
});
</script>

<style lang="less" scoped>
.tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tabselect {
  display: flex;

  .tabitem {
    font-size: @font-size;
    color: @font-color;
    position: relative;
    padding-bottom: 14px;
    margin-right: 20px;
    line-height: 30px;
    height: 30px;

    span {
      position: absolute;
      background: @theme-color;
      display: none;
      bottom: 0;
      left: 10%;
      right: 10%;
      height: 3px;
      border-radius: 1.5px;
    }
  }

  .on {
    font-weight: bolder;

    span {
      display: block;
    }
  }
}

.btns {
  display: flex;

  button {
    margin-left: 10px;
    font-size: 30px;
    color: @font-secondary-color;
    background: none;
    display: flex;
    padding: 0 10px;
    align-items: center;
    line-height: 30px;
    height: 30px;

    .dot {
      font-size: 10px;
      padding: 5px 15px;
      background: red;
      color: #fff;
      border-radius: 30px;
      margin-left: 10px;
    }
  }
}

.FileBox {
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  box-shadow: 0 0 20px #ece2e2;
  border: 0 0 20px 20px;
}

.search {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  background: #f3f3f3;

  .searchinput {
    flex: 1;
    min-width: 0;
    background: #f3f3f3;
  }

  .searchSelect {
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-right: 1px solid #e1e1e1;
    margin: 10px 0;

    .text {
      font-size: 24px;
      color: #666;
      line-height: 24px;
    }

    img {
      width: 20px;
      display: block;
      margin-left: 10px;
    }
  }

  button {
    font-size: @font-size;
    font-family: "Bold";
    color: @font-color;
    line-height: @font-size;
    width: 80px;
    height: 54px;
    border-radius: 10px;
    background: none;
  }
}

.selectbox {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 60;
  pointer-events: none;

  .mask {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.3s linear;
  }

  .selectmain {
    width: 80vw;
    position: absolute;
    left: 120vw;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    border-radius: 20px 0 0 20px;
    box-shadow: 0 0 20px #e1e1e1;
    padding: 20px 0 20px 20px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    transition: left 0.3s linear;
  }

  .seletcont {
    flex-grow: 1;
    height: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
  }

  .seleitem {
    margin-bottom: 20px;
  }

  .tit {
    font-size: 28px;
    color: #333;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .selectBtns {
    display: flex;
    flex-wrap: wrap;

    button {
      width: calc(25% - 20px);
      margin-right: 20px;
      border: 3px solid #e1e1e1;
      background: none;
      font-size: 24px;
      height: 60px;
      line-height: 60px;
      margin-bottom: 20px;
      border-radius: 10px;
    }

    .on {
      border: 3px solid @theme-secondary-color;
      color: @font-white-color;
      background: @theme-secondary-color;
    }
  }
}

.openbox {
  pointer-events: auto;

  .mask {
    background: rgba(0, 0, 0, 0.6);
  }

  .selectmain {
    left: 20vw;
  }
}

.daterange {
  margin-right: 20px;
  border: 3px solid #e1e1e1;
  font-size: 24px;
  height: 60px;
  line-height: 60px;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 0 20px;
  display: inline-block;
}

.active {
  border: 3px solid @theme-secondary-color;
  color: @font-white-color;
  background: @theme-secondary-color;
}

.bottombtn {
  display: flex;

  button {
    background: none;
    font-size: 28px;
    height: 66px;
    line-height: 66px;
    border-radius: 10px;
    margin-right: 20px;
  }

  .reset {
    flex: 1;
    border: 3px solid @theme-secondary-color;
    color: @theme-secondary-color;
  }

  .confirm {
    flex: 2;
    border: 3px solid @theme-secondary-color;
    background: @theme-secondary-color;
    color: @font-white-color;
  }
}</style>
