<template>
  <div class="showdata" :class="minidata ? 'minidata' : ''">
    <div class="datashow">
      <div
        class="item"
        v-for="(item, index) in dataArray"
        :key="index"
        :class="dataType == 'three' ? 'itemthree' : dataType == 'one' ? 'itemone' : ''"
      >
        <div class="text">{{ item.text?item.text:item.title }}</div>
        <div class="math">{{ item.val }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs } from "vue";
const props = defineProps({
  dataArray: {
    type: Array,
    required: false,
  },
  dataType: {
    type: String,
    required: false,
  },
  minidata: {
    type: Boolean,
    required: false,
  },
});

const { dataArray, dataType, minidata } = toRefs(props);
</script>

<style lang="less" scoped>
.showdata {
  padding: 0 20px;
}

.datashow {
  background: @font-white-color;
  box-shadow: 0 0 10px #d7d7d7;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0 0 0;
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    min-width: 25%;
    margin-bottom: 30px;
    .text {
      color: @font-color;
      font-size: @font-size;
      line-height: @font-size;
      margin-bottom: 20px;
      font-weight: bolder;
      font-family: "Regular";
    }
    .math {
      color: @font-third-color;
      font-size: @font-size;
      line-height: @font-size;
      font-family: "Regular";
    }
  }
  .itemthree {
    min-width: 33.33%;
  }
  .itemone {
    min-width: 100%;
  }
}

.minidata {
  padding: 0;
  .datashow {
    box-shadow: none;
    background: #f3f3f3;
  }
}
</style>
