<template>
  <div class="StoreManagement">
    <div class="topimg"><img src="@/assets/img/toppic1.png" alt="" /></div>
    <div class="file">
      <FileBox :fileData="fileData" @setListType="setListType" @setData="setData" ref="fileBox" />
    </div>
    <div class="contant">
      <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" :immediate-check="false"
                @load="onLoad" class="listload">
        <NewDataShow :dataArray="dataArray" :dataType="dataType" v-if="showDataShow" />
        <StoreList :listdata="list" :listtype="listtype" :detailUrl="detailUrl" :detailUrlList="detailUrlList" @resetList="resetList" />
      </van-list>
    </div>

    <FloatingBubble v-if="showTopButton" :buttons="topButtons" />
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { Toast } from "vant";
import FileBox from "@/views/StoreManagement/component/FileBox";
import FloatingBubble from "@/components/FloatingBubble";
import StoreList from "@/views/StoreManagement/component/StoreList";
import NewDataShow from "@/components/NewDataShow";
import wx from "jweixin-js";
import API from "@/axios";
import mitts from "@/bus";

// import mitts from "@/bus";
// import { useRouter } from "vue-router";
// const router = useRouter();

const wxposition = ref();

const fileData = ref({
  selectlist: [],
  filterlist: [],
});

const searchtitle = ref();
const list = ref([]);
const loading = ref(false);
const finished = ref(false);

const fileBox = ref(null);

const ApiData = reactive({
  top_nav: 1,
  store_nav: 1,
  title_type: 1,
  title: "",
  have_device: 0,
  date_range: "",
  // is_self: 0,
  page: 1,
  limit: 15,
  lat: 24.620793,
  lng: 118.03478,
  store_type: 0,
  load_store: false,
  load_area: false,
  load_collect: false,
  sys_cost_type: 0,
});

const dataArray = ref();

const tabslist = ref([]);

const dataType = ref("");
const listtype = ref(1);

const showDataShow = ref(true);
const topButtons = ref([]);
const showTopButton = ref(false);
const detailUrl = ref('');
const detailUrlList = ref([]);

function getwxconfig() {
  API({
    url: "/web/wxJs",
    method: "post",
    data: {
      url: encodeURIComponent(window.location.href.split("#")[0]),
    },
  }).then((res) => {
    if (res.data.status == 200) {
      wxposition.value = res.data.data;

      wx.config({
        debug: false,
        appId: wxposition.value.appId,
        timestamp: wxposition.value.timestamp,
        nonceStr: wxposition.value.nonceStr,
        signature: wxposition.value.signature,
        jsApiList: [
          "checkJsApi",
          "getLocation",
        ],
      });

      wx.ready(function () {
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      });
      getwxLocalposition();
    }
  });
}

function getwxLocalposition() {
  wx.getLocation({
    type: "wgs84", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
    success: function (res) {
      console.log(res.longitude, res.latitude);
      if (res) {
        ApiData.lat = res.latitude
        ApiData.lng = res.longitude
      }
    },
  });
}

const onLoad = () => {
  if (listtype.value == 1) {
    getStoreList();
  } else if (listtype.value == 2) {
    getAreaList();
  } else if (listtype.value == 3) {
    collectList();
  } else {
    getStoreList();
  }

  finished.value = true;
  loading.value = false;
};

function resetList() {
  list.value = []
  ApiData.page = 1;
  if (listtype.value == 1) {
    getStoreList();
  } else if (listtype.value == 2) {
    getAreaList();
  } else if (listtype.value == 3) {
    collectList();
  } else {
    getStoreList();
  }
}
function getStoreList(){
  if(ApiData.load_store){
    setTimeout(function(){
      ApiData.load_store = 1;
      getStoreListHandle()
    }, 500);
  } else {
    ApiData.load_store = 1;
    getStoreListHandle();
  }
}
function getStoreListHandle() {
  API({
    url: "/api/storeList",
    method: "post",
    data: {
      top_nav: ApiData.top_nav,
      store_nav: ApiData.store_nav,
      title_type: ApiData.title_type,
      title: ApiData.title,
      have_device: ApiData.have_device,
      date_range: ApiData.date_range,
      // is_self: ApiData.is_self,
      sys_cost_type: ApiData.sys_cost_type,
      page: ApiData.page,
      limit: ApiData.limit,
    },
  }).then((res) => {
    if (res.data.status == 200) {
      dataType.value = "";
      showDataShow.value = true;
      dataArray.value = res.data.data.count_data;
      fileData.value.selectlist = res.data.data.store_nav;
      tabslist.value = res.data.data.top_nav;
      fileData.value.filterlist = res.data.data.condition;
      searchtitle.value = res.data.data.search_title;

      let newArr = [];
      list.value = list.value.concat(res.data.data.list);
      list.value.forEach((el) => {
        const result = newArr.findIndex((ol) => {
          return el.id === ol.id;
        });
        if (result !== -1) {
          newArr[result] = el;
        } else {
          newArr.push(el);
        }
      });
      list.value = newArr;

      ApiData.page++;
      if (res.data.data.list.length === 0) {
        finished.value = true;
      } else {
        finished.value = false;
      }
      //总线传参赋值顶部菜单
      mitts.emit("secondMenus",  res.data.data.top_nav)
      mitts.emit("auths", res.data.data.top_nav_auth)
      //总线传参赋值底部菜单
      mitts.emit('navs', res.data.data.main_nav);
      mitts.emit("toptitle", true);
      mitts.emit('navindex', 'business');

      detailUrl.value = (res.data.data.detail_url ?? '');
      // 收藏门店存在门店详情和公海门店详情，链接不同，需要返回数组
      detailUrlList.value = (res.data.data.detail_url_list ?? []);

      //初始化顶部按钮，比如增删查改
      let h5Button = (res.data.data.top_buttons ?? []);
      if (h5Button) {
        //过滤导出、隐藏字段等按钮
        h5Button.forEach((item)=>{
          if(item.type==1){
            topButtons.value.push(item);
          }
        })
        if(topButtons.value.length > 0){
          showTopButton.value = true;
        }
      }
    } else {
      Toast.fail(res.data.msg);
    }
    ApiData.load_store = 0;
  });
}
function getAreaList(){
  if(ApiData.load_area){
    setTimeout(function(){
      ApiData.load_area = 1;
      getAreaListHandle()
    }, 500);
  } else {
    ApiData.load_area = 1;
    getAreaListHandle();
  }
}

function getAreaListHandle() {
  API({
    url: "/api/areaList",
    method: "post",
    data: {
      top_nav: ApiData.top_nav,
      store_nav: ApiData.store_nav,
      title_type: ApiData.title_type,
      title: ApiData.title,
      lat: ApiData.lat,
      lng: ApiData.lng,
      page: ApiData.page,
      limit: ApiData.limit,
    },
  }).then((res) => {
    if (res.data.status == 200) {
      dataArray.value = res.data.data.count_data;
      fileData.value.selectlist = res.data.data.store_nav;
      tabslist.value = res.data.data.top_nav;
      searchtitle.value = res.data.data.search_title;
      fileData.value.filterlist = res.data.data.condition;
      dataType.value = "one";
      showDataShow.value = true;

      let newArr = [];
      list.value = list.value.concat(res.data.data.list);
      list.value.forEach((el) => {
        const result = newArr.findIndex((ol) => {
          return el.id === ol.id;
        });
        if (result !== -1) {
          newArr[result] = el;
        } else {
          newArr.push(el);
        }
      });
      list.value = newArr;

      ApiData.page++;
      if (res.data.data.list.length === 0) {
        finished.value = true;
      } else {
        finished.value = false;
      }

      detailUrl.value = (res.data.data.detail_url ?? '');
      // 收藏门店存在门店详情和公海门店详情，链接不同，需要返回数组
      detailUrlList.value = (res.data.data.detail_url_list ?? []);

      //初始化顶部按钮，比如增删查改
      let h5Button = (res.data.data.top_buttons ?? []);
      if (h5Button) {
        //过滤导出、隐藏字段等按钮
        h5Button.forEach((item)=>{
          if(item.type==1){
            topButtons.value.push(item);
          }
        })
        if(topButtons.value.length > 0){
          showTopButton.value = true;
        }
      }
    } else {
      Toast.fail(res.data.msg);
    }
    ApiData.load_area = 0;
  });
}

function collectList(){
  if(ApiData.load_collect){
    setTimeout(function(){
      ApiData.load_collect = 1;
      collectListHandle()
    }, 500);
  } else {
    ApiData.load_collect = 1;
    collectListHandle();
  }
}

function collectListHandle() {
  API({
    url: "/api/collectList",
    method: "post",
    data: {
      top_nav: ApiData.top_nav,
      store_nav: ApiData.store_nav,
      title_type: ApiData.title_type,
      title: ApiData.title,
      lat: ApiData.lat,
      lng: ApiData.lng,
      page: ApiData.page,
      limit: ApiData.limit,
      date_range: ApiData.date_range,
      store_type: ApiData.store_type,
    },
  }).then((res) => {
    if (res.data.status == 200) {
      dataArray.value = res.data.data.count_data;
      fileData.value.selectlist = res.data.data.store_nav;
      tabslist.value = res.data.data.top_nav;
      searchtitle.value = res.data.data.search_title;
      fileData.value.filterlist = res.data.data.condition;
      showDataShow.value = false;

      let newArr = [];
      list.value = list.value.concat(res.data.data.list);
      list.value.forEach((el) => {
        const result = newArr.findIndex((ol) => {
          return el.id === ol.id;
        });
        if (result !== -1) {
          newArr[result] = el;
        } else {
          newArr.push(el);
        }
      });
      list.value = newArr;

      ApiData.page++;
      if (res.data.data.list.length === 0) {
        finished.value = true;
      } else {
        finished.value = false;
      }

      detailUrl.value = (res.data.data.detail_url ?? '');
      // 收藏门店存在门店详情和公海门店详情，链接不同，需要返回数组
      detailUrlList.value = (res.data.data.detail_url_list ?? []);

      //初始化顶部按钮，比如增删查改
      let h5Button = (res.data.data.top_buttons ?? []);
      if (h5Button) {
        //过滤导出、隐藏字段等按钮
        h5Button.forEach((item)=>{
          if(item.type==1){
            topButtons.value.push(item);
          }
        })
        if(topButtons.value.length > 0){
          showTopButton.value = true;
        }
      }
    } else {
      Toast.fail(res.data.msg);
    }

    ApiData.load_collect = 0;
  });
}

function setListType(val) {
  list.value = [];
  listtype.value = val;
  ApiData.page = 1;

  if (listtype.value == 1) {
    getStoreList();
  } else if (listtype.value == 2) {
    getAreaList();
  } else if (listtype.value == 3) {
    collectList();
  } else {
    getStoreList();
  }

  setTimeout(() => {
    fileBox.value.inisearch(searchtitle.value);
  }, 1000)

}

function setData(val) {
  list.value = [];
  ApiData.page = 1;
  ApiData.title_type = val.title_type;
  ApiData.title = val.title;
  ApiData.date_range = val.date_range;
  ApiData.have_device = val.have_device;
  // ApiData.is_self = val.is_self;
  ApiData.store_type = val.store_type;
  ApiData.sys_cost_type = val.sys_cost_type;

  if (listtype.value == 1) {
    getStoreList();
  } else if (listtype.value == 2) {
    getAreaList();
  } else if (listtype.value == 3) {
    collectList();
  }

}

// const tabindex = ref(0);
// function tabClick(index) {
//   tabindex.value = index;
// }

onMounted(() => {
  getwxconfig();
  getStoreList();

  setTimeout(() => {
    mitts.emit("closeLoading");
    fileBox.value.inisearch(searchtitle.value);
  }, 1000);
});
</script>

<style lang="less" scoped>
.tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
}

.tabselect {
  display: flex;

  .tabitem {
    font-size: @font-size;
    color: @font-color;
    position: relative;
    padding-bottom: 14px;
    margin-right: 20px;

    span {
      position: absolute;
      background: @theme-color;
      display: none;
      bottom: 0;
      left: 10%;
      right: 10%;
      height: 3px;
      border-radius: 1.5px;
    }
  }

  .on {
    font-weight: bolder;

    span {
      display: block;
    }
  }
}

.btns {
  display: flex;

  button {
    margin-left: 10px;
    font-size: 30px;
    color: @font-secondary-color;
    background: none;
    display: flex;
    padding: 10px;
    align-items: center;

    .dot {
      font-size: 10px;
      padding: 5px 15px;
      background: red;
      color: #fff;
      border-radius: 30px;
      margin-left: 10px;
    }
  }
}

.StoreManagement {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
}
.file{
  position: relative;
  margin: 0 20px;
  margin-top: -220px;
  padding: 20px;
  background: @font-white-color;
  box-shadow: 0 0 10px #d7d7d7;
  border-radius: 10px;
  box-sizing: border-box;
}
.contant {
  flex-grow: 1;
  height: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px 0;
  box-sizing: border-box;
  background: #f5f5f5;
}
.foot {
  height: 116px;
}
.topimg {
  width: 100%;
  img {
    width: 100%;
    display: block;
  }
}
</style>
