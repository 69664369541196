<template>
  <div class="floatingbubble">
      <div class="mask" v-if="openpup" @click="topClick"></div>
      <div class="floatbox" :class="openpup ? 'openpup' : ''">
          <div class="menu">
            <div
              class="menuitem"
              :style="{ transform: 'rotate(' + (-120 + 60 * index) + 'deg)' }"
              v-for="(item, index) in items"
              :key="index"
              @click="toRout(item)"
            >
              <div
                class="itemcont"
                :style="{ transform: 'rotate(' + (120 - 60 * index) + 'deg)' }"
              >
                <div class="icon"><img :src="item.icon ? item.icon : 'img/value.png'" alt="" /></div>
                <div class="text" style="color: black;">{{ item.title }}</div>
              </div>
            </div>
          </div>
        <div class="topicon" @click="topClick">
          <van-icon name="add" />
        </div>
      </div>
  </div>
</template>

<script setup>
import {defineProps, ref, toRefs, onMounted} from "vue";
import { useRouter } from "vue-router";
const props = defineProps({
  buttons: {
    type: Object,
    required: false,
  },
});

const {buttons} = toRefs(props);
const router = useRouter();
const openpup = ref(false);
const items = ref([]);//实际用到的配置

const defaultItem = ref([
  {
    title: "拜访打卡",
    icon: require("@/assets/img/popicon1.png"),
    url:'/web/addVisit',
    is_event:true,
  },
  {
    title: "新增门店",
    icon: require("@/assets/img/popicon2.png"),
    url:'/StoreManagement/ApplyStore',
    is_event:false,
  },
  {
    title: "新增设备",
    icon: require("@/assets/img/popicon3.png"),
    url:'/StoreManagement/ApplyEquipment',
    is_event:false,
  },
]);

onMounted(()=>{
  //配置
  if((buttons.value == undefined) || (buttons.value == '') || (buttons.value == [])){
    items.value = defaultItem.value;
  }else{
    items.value = buttons.value;
    items.value.map((item)=>{
      if(item.icon == undefined){
        switch (item.url){
          case '/web/addVisit':
            item.icon = require("@/assets/img/popicon1.png");
            break
          case '/StoreManagement/ApplyStore':
            item.icon = require("@/assets/img/popicon2.png");
            break
          case '/StoreManagement/ApplyEquipment':
            item.icon = require("@/assets/img/popicon3.png");
            break
          default :
            item.icon = '/img/value.png';
        }
      }
      return item;
    })
  }
});
function topClick() {
  // 判断 items.value 是否存在 或者 其他的 条件 自定
  if(items.value && items.value.length > 1){
    // 符合条件打开 浮窗
    openpup.value = !openpup.value;
  }else{
    // 不符合条件 不打开浮窗 但是跳转
    openpup.value = false
    toRout(items.value[0])
  }
}

function toRout(item) {
  if (item.is_event == true) {
    router.push({
      path: "/event/event",
      query: { url: encodeURIComponent(item.url) },
    });
  }else{
    router.push({
      path: item.url,
      query: {},
    });
  }
}
</script>

<style lang="less" scoped>
.floatingbubble {

}

.mask{
  background: rgba(0,0,0,0.6);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 40;
}

.floatbox{
  position: fixed;
  right: 35px;
  bottom: 220px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 0 0 #dfdfdf;
  transition: background 0.3s linear;
  -webkit-transition: background 0.3s linear;
  z-index: 60;
}

.menu {
  position: relative;
  width: 320px;
  height: 320px;
  left: 50%;
  top: 50%;
  margin: -160px 0 0 -160px;
  transform: scale(0);
  -webkit-transform: scale(0);
  transition: transform 0.3s linear;
  -webkit-transition: -webkit-transform 0.3s linear;
}

.menuitem {
  transform-origin: 160px 160px;
  position: absolute;
  .itemcont {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    .icon {
      width: 60px;
      margin-bottom: 10px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: block;
      }
    }
    .text {
      font-size: 24px;
      line-height: 24px;
      color: #333;
    }
  }
}
.topicon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 120px;
  height: 120px;
  margin: -60px 0 0 -60px;
  font-size: 120px;
  line-height: 120px;
  color: #486ffb;
  z-index: 10;
  box-shadow: 0 0 20px #dfdfdf;
  border-radius: 50%;
  transform: rotate(0);
  transition: transform 0.3s linear;
  -webkit-transform: rotate(0);
  -webkit-transition: -webkit-transform 0.3s linear;
  background: #fff;
}

.openpup {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0 20px #dfdfdf;
  width: 450px;
  height: 450px;
  right: -130px;
  bottom: 55px;
  .menu {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  .topicon {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
}
</style>
